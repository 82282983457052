import { Component } from '@angular/core';

@Component({
  selector: 'app-medeildownload',
  templateUrl: './medeildownload.component.html',
  styleUrls: ['./medeildownload.component.scss']
})
export class MedeildownloadComponent {
  indianEditionClicked: boolean = false;
  internationalEditionClicked: boolean = false;

  toggleIndianEdition() {
    this.indianEditionClicked = true;
    this.internationalEditionClicked = false;
  }

  toggleInternationalEdition() {
    this.internationalEditionClicked = true;
    this.indianEditionClicked = false;
  }
}
