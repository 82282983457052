
   
<app-navbar-style-one></app-navbar-style-one>
<app-navbar-style-two></app-navbar-style-two>

<div class="banner-area-two client-slider owl-carousel owl-theme">

   
    <div class="client-item">
      
      <!-- <link rel="preload" href="https://medeilpos.blob.core.windows.net/websiteimage/Pharmacyapplication - Medeilcloud pos.png" as="image" > -->
        <img src="https://medeilpos.blob.core.windows.net/websiteimage/Pharmacyapplication - Medeilcloud pos.png"   alt="Pharmacyapplication - Medeilcloud pos" >
      
        <div class="overlay-buttons row">
            <div class="col-lg-6">
         <a href="https://secure.medeil.io/medeilpos/eng/23pos/#/usersignup" target="_blank"  rel="noopener"> 
          <!-- rel="noopener" -->
            <button type="button" class="default-btn1" >14 Days Free Trial</button>
         </a>
         <!-- <button (click)="navigatepostrial()" type="button" class="default-btn1" >14 Days Free Trial</button> -->
            </div>
            <div class="col-lg-6">
                <button type="button" class="default-btn1" (click)="appointment('Medeil Cloud POS')">Demo Request</button>
            </div>
              
              
        </div>
      
    </div>
    <div class="client-item">
        
       
        <img src="https://medeilpos.blob.core.windows.net/websiteimage/retailchainsoftware - medeilcloud HQ.png" alt="retailchainsoftware - medeilcloud HQ"   >
        <div class="overlay-buttonshq">
           
            <button type="button" class="hqbtn" (click)="appointment('Medeil Cloud HQ')">Demo Request</button>
        </div>
      
    </div>
    <div class="client-item">
       
      
        <img src="https://medeilpos.blob.core.windows.net/websiteimage/pharmacymanagementsystem - Medeil.png" alt="pharmacymanagementsystem - Medeil" > 
        <div class="overlay-buttonsstand row">
            <div class="col-lg-6">
                <button type="button" class="standalonebtn">10 Days Trial</button>
            </div>
            <div class="col-lg-6">
                <button type="button" class="standalonebtn" (click)="appointment('Medeil Standalone')">Free Edition Request</button>
            </div>
           
           
        </div>
       
      
    </div>
    <!-- <div class="client-item">
      
      
        <img src="assets/img/home-one/hosbayg.png" alt="Images" >
        <div class="overlay-buttonshosbay">
          
            <button type="button" class="hosbaybtn">Demo Request</button>
        </div>
      
    </div> -->
    <div class="client-item">
 
       
        <img src="https://medeilpos.blob.core.windows.net/websiteimage/Wholesale management software - Allotmed.png" alt="Wholesale management software - Allotmed" >
        <div class="overlay-buttonsallotmed">
            <button type="button" class="allotmedbtn" (click)="appointment('Allotmed')">Demo Request</button>
        </div>
      
    </div>
    
</div>

<!-- appointment form -->
<ngx-smart-modal #enquirypos identifier="enquirypos" [closable]="false">


   <div class="custom-modal">
      <button class="close-btn" (click)="closeModal()">X</button>
      <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Enquiry Form</h5>
      </div>
        <div class="modal-body">
          <form [formGroup]="enquiryForm">
            <div class="form-group">
              <label for="recipient-name" class="col-form-label"><strong>Name</strong><span class="star">*</span></label>
              <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="leadname" required>
            </div>
            <div class="form-group">
              <label for="message-text" class="col-form-label"><strong>Organisation</strong></label>
              <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="organizationname" required>
            </div>
            <div class="form-group">
              <label for="message-text" class="col-form-label"><strong>Email</strong><span class="star">*</span></label>
              <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="primaryemail" required>
              <div *ngIf="enquiryForm.controls.primaryemail.invalid && (enquiryForm.controls.primaryemail.dirty || enquiryForm.controls.primaryemail.touched)">
               <div *ngIf="enquiryForm.controls.primaryemail.errors?.required" style="color: red;">Email is required.</div>
               <div *ngIf="enquiryForm.controls.primaryemail.errors?.pattern" style="color: red;">Invalid email format.</div>
             </div>
            </div>
            <div class="form-group">
              <label for="message-text" class="col-form-label"><strong>Country</strong></label>
              <!-- <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="countryname1"> -->
              <select class="form-control"  formControlName="countryname1" style="font-size: 12px;" (change)="countrychange()">
               <!-- (change)="countrychange()" -->
               <option select disabled value="0" >---Select Country---</option>
               <option  value="{{ country[1] }}" *ngFor="let country of totalcountry">{{country[1]}}</option>
   
             </select>
            </div>
            <div class="form-group">
              <label for="message-text" class="col-form-label"><strong>Mobile</strong><span class="star">*</span></label>
              <div class="row">
                <div class="col-lg-3">
                  <input type="text" name="rec" readonly class="form-control mobile-field" [(ngModel)]="dialcode" id="recipient-name" formControlName="phonedialcode">
                </div>
                <div class="col-lg-9">
                  <input type="text" name="rec" class="form-control mobile-field" maxlength="15" id="recipient-name" formControlName="primaryphone"
                  autocomplete="off" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')">
                </div>
              </div>
             
            </div>
            <div class="form-group">
              <label for="message-text" class="col-form-label"><strong>Description</strong></label>
              <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="address1">
            </div>
            <!-- <div class="form-group">
              <label for="message-text" class="col-form-label"><strong>Enter captcha</strong></label>
              <input type="text" name="rec" class="form-control" id="recipient-name">
            </div> -->
            <label for="message-text" class="col-form-label captchalabel"><strong>Enter captcha</strong></label>
            <div class="input-group">
               <input type="text" class="form-control ecaptcha" required formControlName="enteredcaptcha" required="required"
                (change)="checkCaptcha()"/>
               <input type="text" class="form-control gcaptcha" formControlName="generatedcaptcha" readonly
               [(ngModel)]="captcha"/>
               <i class="fa fa-arrows-rotate refresh" (click)="generatecaptcha()"></i>
             </div>
             <div *ngIf="ecaptcha">
               <p style="color: red">{{ errorcaptcha }}</p>
             </div>
            <div class="form-group">
             
                  <label class="container1 mt-3">I Accept Terms & Conditions & Privacy Policy
                      <input type="checkbox" checked="checked" formControlName="checkbox">
                      <span class="checkmark"></span>
                  </label>
            </div>
            <div class="btn-group mt-3" *ngIf="!showElement">
              <button type="button" class="enquiry-btn" (click)="submitenquery()" >Submit</button>

            </div>
           
            
          </form>
        </div>
  </div>
</ngx-smart-modal>
<ngx-smart-modal #appointmentform identifier="appointmentform" [closable]="false">


   <div class="custom-modal"  >
      <button class="close-btn" (click)="closeModal()">X</button>
       <div class="modal-header-appointment">
           <h4 class="modal-title" id="exampleModalLabel">Demo Request</h4>
       </div>
         <div class="modal-body">
        
            <div class="row">
               <div class="col-lg-12">
                  <mat-form-field >
                    
                     <input matInput [matDatepicker]="picker" placeholder="Choose a date" (dateChange)="slectdate($event)" >
                     <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                     <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                 
               </div>
               <div  align="center" *ngIf="showElement">
                  <img height="200px" width="200px" src="https://medeilpos.blob.core.windows.net/posimage/loading.gif" alt="pharmacy gif image" loading="lazy">
                </div>
               <div class="col-lg-12" *ngIf="appointment_time">
                  
                   <div class="button-container">
                     <button class="flex-button" *ngFor="let time of timeSlots" (click)="selectTime(time)" [ngClass]="{ 'clicked': selectedTime === time }">{{ time }}</button>
                 </div>
                 <div  align="center" *ngIf="showElementsave">
                  <img height="100px" width="100px" src="https://medeilpos.blob.core.windows.net/posimage/loading.gif" alt="pharmacy gif image" loading="lazy">
                </div>
                   <div class="shedule-submit">
                     <button class="shedulebtn" (click)="savelead()">Submit</button>
                   </div>
               </div>
            </div>

         </div>
   </div>
</ngx-smart-modal>

<div class="newslider" >

    <div class="place-list-item">
        <img src="https://medeilpos.blob.core.windows.net/websiteimage/anu-pharmacy.webp" loading="lazy" alt="medeil client image for anu-pharmacy" loading="lazy">
    </div>

    <div class="place-list-item">
        <img src="https://medeilpos.blob.core.windows.net/websiteimage/apollo-hospitals.webp" loading="lazy" alt="medeil client image for apollo-hospitals" loading="lazy">
    </div>

    <div class="place-list-item">
        <img src="https://medeilpos.blob.core.windows.net/websiteimage/bellamed-healthcare.webp" loading="lazy" alt="medeil client image for bellamed-healthcare">
    </div>

    <div class="place-list-item">
        <img src="https://medeilpos.blob.core.windows.net/websiteimage/bk-heart-hospital.webp" loading="lazy" alt="medeil client image for bk-heart-hospital">
    </div>

    <div class="place-list-item">
        <img src="https://medeilpos.blob.core.windows.net/websiteimage/booncholai-super-market.webp" loading="lazy" alt="medeil client image for booncholai-super-market">
    </div>

    <div class="place-list-item">
        <img src="https://medeilpos.blob.core.windows.net/websiteimage/emedsure.webp" loading="lazy" alt="medeil client image for emedsure">
    </div>

    <div class="place-list-item">
        <img src="https://medeilpos.blob.core.windows.net/websiteimage/express-pharmacy.webp" loading="lazy"  alt="medeil client image for express-pharmacy">
    </div>

    <div class="place-list-item">
        <img src="https://medeilpos.blob.core.windows.net/websiteimage/falcon-healthcare.webp" loading="lazy"  alt="medeil client image for falcon-healthcare">
    </div>

    <div class="place-list-item">
        <img src="https://medeilpos.blob.core.windows.net/websiteimage/global-bridge-pharmaceutical.webp" loading="lazy" alt="medeil client image for global-bridge-pharmaceutical">
    </div>

    <div class="place-list-item">
        <img src="https://medeilpos.blob.core.windows.net/websiteimage/hawi-family-hostpital.webp" loading="lazy" alt="medeil client image for hawi-family-hostpital">
    </div>

    <div class="place-list-item">
        <img src="https://medeilpos.blob.core.windows.net/websiteimage/henry-jackson-foundation.webp" loading="lazy" alt="medeil client image for henry-jackson-foundation">
    </div>

    <div class="place-list-item">
        <img src="https://medeilpos.blob.core.windows.net/websiteimage/imcri.webp" loading="lazy" alt="medeil client image for imcri">
    </div>

</div>


<section class="city-area-two pb-70"  >

    <div class="container">
        <div class=" section-title gradient-text section-new mt-2">
            <h1>Explore our Product</h1>
        </div>

        <div class="align-items-center no-scroll flex-container mt-3">
            <!-- <div class="city-card owl-carousel owl-theme"> -->
                <div class="flexbox" (click)="selectDiv(1)" [ngClass]="{ 'selected': selectedDiv === 1 }">Medeil</div>
                <div class="flexbox" (click)="selectDiv(2)" [ngClass]="{ 'selected': selectedDiv === 2 }">Medeil Cloud POS</div>
                <div class="flexbox" (click)="selectDiv(3)" [ngClass]="{ 'selected': selectedDiv === 3 }">Medeil Cloud HQ</div>
                <!-- <div class="flexbox" (click)="selectDiv(4)" [ngClass]="{ 'selected': selectedDiv === 4 }">Hosbay</div> -->
                <div class="flexbox" (click)="selectDiv(5)" [ngClass]="{ 'selected': selectedDiv === 5 }">Allotmed</div>
            <!-- </div> -->
           
           
        </div>
        <div class="row product-container pt-45 mt-5" *ngIf="pos">
            <div class="col-lg-4 col-md-12">
                <div class="city-card new-city">
                    <p style="font-size: 17px;color: black;font-weight: 600;">
                        Discover the ultimate solution for your retail business with our top-rated POS software. Streamline operations, boost sales, and stay ahead of the competition. Elevate Your Retail Operations with Cutting-Edge Technology with Unmatched Efficiency along with Experience the Power of Medeilcloud POS Software. Streamline Sales, Inventory, Vendor management, Customer Relationship, and Financial Management Seamlessly Implement the Medeilcloud POS TO Dominate the Retail Industry with Our Superior Functionality.
                    </p>
                    <!-- <a routerLink="/"><img src="assets/img/products/Eng black iphone.png" alt="Images"></a> -->
                    <!-- <div class="content content-new">
                        <a routerLink="/"><h3>Fort Worth</h3></a>
                        <span>17 Locations</span>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="city-card">
                    <a routerLink="/"><img src="https://medeilpos.blob.core.windows.net/websiteimage/cloudpharmacysoftware - medeilcloudpos.png" alt="cloudpharmacysoftware - medeilcloudpos" (click)="enquirynow('Medeil Cloud POS')" loading="lazy"></a>
                    <!-- <div class="content content-new">
                        <a routerLink="/"><h3>San Antonio</h3></a>
                        <span>13 Locations</span>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-12 mt-4">
                <div class="city-card row display-card">
                    
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div class="vertical-list">
                            <a routerLink="/vertical/pharmacymanagementsoftware">Pharmacies</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div class="vertical-list">
                            <a routerLink="/vertical/opticalshopmanagementsystem">Optical</a>
                        </div>
                    </div>
                   
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div class="vertical-list">
                            <a routerLink="/vertical/softwareformedicalstore">Healthcare stores</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div class="vertical-list">
                            <a routerLink="/vertical/beautysoftware">Beauty & Cosmetics</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div class="vertical-list">
                            <a routerLink="/vertical/veterinarysoftware">Veterinary stores</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div class="vertical-list">
                            <a routerLink="/vertical/agriculturesoftware">Agrochemical</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div class="vertical-list">
                            <a routerLink="/vertical/medicaldevicesoftware">Medical Device</a>
                        </div>
                    </div>
                </div>
                
            </div>
            <hr class="mt-4">
            <div class="col-lg-10 displaycol mt-4 ">
                Be Ahead of the Competition with Our Innovative Approach along with Medeilcloud POS, and your Journey to Retail Success Begins Here. Start exploring the 14 Days Trial Edition as per your Requirements
            </div>
            <div class="col-lg-2 col-sm-6 offset-sm-3 offset-lg-0 mt-4">
                <button type="submit" class="default-btn sign-up-btn" (click)="enquirynow('Medeil Cloud POS')">Sign Up</button>
            </div>

         
        </div>
        <div class="row product-container pt-45 mt-5" *ngIf="medeil">
            <div class="col-lg-4 col-md-12">
                <div class="city-card new-city">
                    <p style="font-size: 17px;color: black;font-weight: 600;">
                        Discover why Medeil Pharmacy Management System stands out as the best pharmacy software, boasting over 15 years of market experience and being the top-downloaded pharmacy software across several countries. Say goodbye to software woes and hello to seamless pharmacy management with Medeil. End-to-end pharmacy process management with the single most economical and reliable software. Try it today and experience the difference!
                    </p>
                </div>
            </div>

                <div class="col-lg-8 col-md-12" >
                    <div class="city-card medeil-card">
                        <a routerLink="/"><img src="https://medeilpos.blob.core.windows.net/websiteimage/pharmacysoftware - medeil standalone.png" loading="lazy" alt="pharmacysoftware - medeil standalone" (click)="enquirynow('Medeil Standalone')"></a>
                        <button class="medeil-btn" (click)="enquirynow('Medeil Standalone')">Download</button>
                    </div>
                  
                </div>
            
            <div class="col-lg-12 mt-4">
                <div class="city-card row display-card">
                   
                  <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/vertical/pharmacymanagementsoftware">Pharmacies</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/vertical/opticalshopmanagementsystem">Optical</a>
                     </div>
                 </div>
                
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/vertical/softwareformedicalstore">Healthcare stores</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/vertical/beautysoftware">Beauty & Cosmetics</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/vertical/veterinarysoftware">Veterinary stores</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/vertical/agriculturesoftware">Agrochemical</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/vertical/medicaldevicesoftware">Medical Device</a>
                     </div>
                 </div>
                </div>
            </div>
            <hr class="mt-4">
            <div class="col-lg-10 displaycol mt-4 ">
                Discover Medeil, the world's leading pharmacy software offering complete business management, inventory control, and unrivaled intelligence reports. Try now!
            </div>
            <div class="col-lg-2 col-sm-6 offset-sm-3 offset-lg-0 mt-4">
                <button type="submit" class="default-btn sign-up-btn"  (click)="enquirynow('Medeil Standalone')">Sign Up</button>
            </div>

         
        </div>

        <div class="row product-container pt-45 mt-5" *ngIf="hq">
            <div class="col-lg-4 col-md-12">
                <div class="city-card new-city">
                    <p style="font-size: 16px;color: black;font-weight: 600;">
                        Discover the ultimate solution for managing your chain stores efficiently. Boost productivity, optimize inventory, and gain insights with our advanced software. Elevate Your Chain Store Management to New Heights with the best software solution to manage your multiple store business with our Experience Unmatched Efficiency with Our Cutting-Edge Software. Be Ahead of the Competition with Our Innovative Approach for addressing all your business needs, and advancement helps your Journey to Chain Store Success Begin Here.
                    </p>
                  
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="city-card">
                    <a routerLink="/"><img src="https://medeilpos.blob.core.windows.net/websiteimage/pharmacychainsoftware - medeilcloud hq.png" loading="lazy" alt="pharmacychainsoftware - medeilcloud hq" (click)="enquirynow('Medeil Cloud HQ')"></a>
                   
                </div>
            </div>

            <div class="col-lg-12 mt-4">
                <div class="city-card row display-card">
                  
                  <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/vertical/pharmacymanagementsoftware">Pharmacies</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/vertical/opticalshopmanagementsystem">Optical</a>
                     </div>
                 </div>
                
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/vertical/softwareformedicalstore">Healthcare stores</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/vertical/beautysoftware">Beauty & Cosmetics</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/vertical/veterinarysoftware">Veterinary stores</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/vertical/agriculturesoftware">Agrochemical</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/vertical/medicaldevicesoftware">Medical Device</a>
                     </div>
                 </div>
                </div>
                
            </div>
            <hr class="mt-4">
            <div class="col-lg-10 displaycol mt-4 ">
                Streamline Your Chain Store Operations with Medeilcloud HQ Advanced Retail Supply Chain Management Software for a better experience with an innovative solution. Access the entire supply chain live and explore it now.
            </div>
            <div class="col-lg-2 col-sm-6 offset-sm-3 offset-lg-0 mt-4">
                <button type="submit" class="default-btn sign-up-btn" (click)="enquirynow('Medeil Cloud HQ')">Sign Up</button>
            </div>

        
        </div>
        <div class="row product-container pt-45 mt-5" *ngIf="allotmed">
            <div class="col-lg-4 col-md-12">
                <div class="city-card new-city">
                    <p style="font-size: 16px;color: black;font-weight: 600;">
                        Revolutionize Your Wholesale Business with Allotmed. Access any order quickly and deliver products immediately to satisfy your Retail Customers with Effortless Operations and Increased Profitability. Elevate Your Distribution Management to New Heights. Optimize Inventory Management with Precision and Ease to Gain Insights and Make Informed Decisions Like Never Before. Experience the Best Wholesale Management Solution to dominate the Market with Our Superior Functionality. Be Ahead of the Competition with Our Innovative Approach, and your Journey to Wholesale Success Begins Here.
                    </p>
                   
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="city-card">
                    <a routerLink="/"><img src="https://medeilpos.blob.core.windows.net/websiteimage/distributionmanagementsoftware- allotmed.png" loading="lazy" alt="distribution management software- allotmed" (click)="enquirynow('Allotmed')"></a>
                   
                </div>
            </div>

            <div class="col-lg-12 mt-4">
                <div class="city-card row display-card">
                    
                  <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/vertical/pharmacymanagementsoftware">Pharmacies</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/vertical/opticalshopmanagementsystem">Optical</a>
                     </div>
                 </div>
                
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/vertical/softwareformedicalstore">Healthcare stores</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/vertical/beautysoftware">Beauty & Cosmetics</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/vertical/veterinarysoftware">Veterinary stores</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/vertical/agriculturesoftware">Agrochemical</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/vertical/medicaldevicesoftware">Medical Device</a>
                     </div>
                 </div>
                </div>
                
            </div>
            <hr class="mt-4">
            <div class="col-lg-10 displaycol mt-4 ">
                Simplify Order Processing and Fulfillment Hassle-Free for your C&F Business or Distribution Management to help you grow exponentially, along with the Ultimate Wholesale Management Software. Just less than 30 minutes to talk to our expert to schedule the demo.
            </div>
            <div class="col-lg-2 col-sm-6 offset-sm-3 offset-lg-0 mt-4">
                <button type="submit" class="default-btn sign-up-btn" (click)="enquirynow('Allotmed')">Sign Up</button>
            </div>
        </div>
    </div>
</section>
<ngx-smart-modal #myModal identifier="myModal" [closable]="false">

    <div class="custom-modal">
        <button class="close-btn" (click)="closepopup()">X</button>
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Enquiry Form</h5>
        </div>
        <div class="modal-body">
         <form [formGroup]="enquiryForm">
           <div class="form-group">
             <label for="recipient-name" class="col-form-label"><strong>Name</strong><span class="star">*</span></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="leadname" required>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Organisation</strong></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="organizationname" required>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Email</strong><span class="star">*</span></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="primaryemail" required>
             <div *ngIf="enquiryForm.controls.primaryemail.invalid && (enquiryForm.controls.primaryemail.dirty || enquiryForm.controls.primaryemail.touched)">
              <div *ngIf="enquiryForm.controls.primaryemail.errors?.required" style="color: red;">Email is required.</div>
              <div *ngIf="enquiryForm.controls.primaryemail.errors?.pattern" style="color: red;">Invalid email format.</div>
            </div>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Country</strong></label>
             <!-- <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="countryname1"> -->
             <select class="form-control"  formControlName="countryname1" style="font-size: 13px;" (change)="countrychange()">
              <!-- (change)="countrychange()" -->
              <option select disabled value="0" >---Select Country---</option>
              <option  value="{{ country[1] }}" *ngFor="let country of totalcountry">{{country[1]}}</option>
  
            </select>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Mobile</strong><span class="star">*</span></label>
             <div class="row">
               <div class="col-lg-3">
                 <input type="text" name="rec" readonly class="form-control mobile-field" [(ngModel)]="dialcode" id="recipient-name" formControlName="phonedialcode">
               </div>
               <div class="col-lg-9">
                 <input type="text" name="rec" class="form-control mobile-field" maxlength="15" id="recipient-name" formControlName="primaryphone"
                 autocomplete="off" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')">
               </div>
             </div>
            
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Description</strong></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="address1">
           </div>
           <!-- <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Enter captcha</strong></label>
             <input type="text" name="rec" class="form-control" id="recipient-name">
           </div> -->
           <label for="message-text" class="col-form-label captchalabel"><strong>Enter captcha</strong></label>
           <div class="input-group">
              <input type="text" class="form-control ecaptcha" required formControlName="enteredcaptcha" required="required"
               (change)="checkCaptcha()"/>
              <input type="text" class="form-control gcaptcha" formControlName="generatedcaptcha" readonly
              [(ngModel)]="captcha"/>
              <i class="fa fa-arrows-rotate refresh" (click)="generatecaptcha()"></i>
            </div>
            <div *ngIf="ecaptcha">
              <p style="color: red">{{ errorcaptcha }}</p>
            </div>
           <div class="form-group">
            
                 <label class="container1 mt-3">I Accept Terms & Conditions & Privacy Policy
                     <input type="checkbox" checked="checked" formControlName="checkbox">
                     <span class="checkmark"></span>
                 </label>
           </div>
           <div  align="center" *ngIf="showElementsave">
            <img height="100px" width="100px" src="https://medeilpos.blob.core.windows.net/posimage/loading.gif" alt="medeil gif" loading="lazy">
          </div>
           <div class="btn-group mt-3">
             <button type="button" class="enquiry-btn" (click)="savesignup()">Submit</button>

           </div>
          
           
         </form>
       </div>
    </div>
   
</ngx-smart-modal>

<div class="video-container">
   <iframe src="https://www.youtube.com/embed/dDcM1iqrYn4" frameborder="0" allowfullscreen></iframe>
</div>


<div class="container mt-4"> 

    <div class="section-title gradient-text">
        <h1>Solution</h1>
    </div>
    
   
    <div class="row pt-20 newfeature">
        <div class="col-lg-2 col-sm-6">
            <div class="category-btn"(click)="solutionlist(1)" >
                
                <ng-container>
                    <ng-container >
                      <div class="text-center" >
                         <label>
                           
                            <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/supplychain.png" alt="supply chain image" loading="lazy" class="fab" />
                               
                            </a>
                           
                         </label>
                         <p class="mt-2">Supply Chain Management</p>
                      </div>
                    </ng-container>
                   </ng-container>
            </div>
        </div>
       
        <div class="col-lg-2 col-sm-6 full-container" >
            <div class="category-btn" (click)="solutionlist(2)">
              
                <ng-container>
                    <ng-container >
                      <div  class="text-center">
                         <label>
                            <a>
                              <img src="https://medeilpos.blob.core.windows.net/websiteimage/manufacturer.png" alt="medeil manufacturer image" loading="lazy" class="fab" />
                              
                               
                            </a>
                           
                         </label>
                         <p class="mt-2">Manufacturer</p>
                      </div>
                    </ng-container>
                   </ng-container>
            </div>
            
        </div>

      

        <div class="col-lg-2 col-sm-6" >
            <div class="category-btn"(click)="solutionlist(3)" >
              
                <ng-container>
                    <ng-container >
                      <div  class="text-center">
                         <label>
                            <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/importexport.png" alt="medeil import export image"  loading="lazy" class="fab" />
                               
                            </a>
                           
                         </label>
                         <p class="mt-2">Importer & Export Companies</p>
                      </div>
                    </ng-container>
                   </ng-container>
            </div>
        </div>

        <div class="col-lg-2 col-sm-6">
            <div class="category-btn" (click)="solutionlist(4)">
               
                <ng-container>
                    <ng-container >
                      <div  class="text-center">
                         <label>
                            <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/holesale.png" alt="medeil holesale image" loading="lazy" class="fab" />
                               
                            </a>
                            
                         </label>
                         <p class="mt-2">C&F & Wholesaler</p>
                      </div>
                    </ng-container>
                   </ng-container>
            </div>
        </div>
        <div class="col-lg-2 col-sm-6">
            <div class="category-btn" (click)="solutionlist(5)">
                
                <ng-container>
                    <ng-container >
                      <div class="text-center">
                         <label>
                            <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/retailchainstore.png" alt="medeil retail chain store" loading="lazy" class="fab" />
                               
                            </a>
                            
                         </label>
                         <p class="mt-2">Pharmacy Chain stores / Brand stores</p>
                      </div>
                    </ng-container>
                   </ng-container>
            </div>
        </div>
        <div class="col-lg-2 col-sm-6" >
            <div class="category-btn" (click)="solutionlist(6)">
               
                <ng-container>
                    <ng-container >
                      <div class="text-center">
                         <label>
                            <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/retail.png" alt="medeil retail image" loading="lazy"class="fab"/>
                               
                            </a>
                           
                         </label>
                         <p class="mt-2">Independent Pharmacy stores</p>
                      </div>
                    </ng-container>
                   </ng-container>
            </div>
        </div>

    </div>
    <section class="top-section">
        <div class="container">
          <div class="row" *ngIf="suplaychain">
            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 start-product">
                <div class="row product-info">
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                       <ng-container>
                        <ng-container >
                          <div class="text-center">
                             <label>
                                <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/healthcare.png" alt="medeil healthcare image" loading="lazy" class="fab" />
                                   
                                </a>
                             
                             </label>
                             <p class="mt-2">Healthcare</p>
                          </div>
                        </ng-container>
                       </ng-container>
                    </div>
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                       
                     
                        <ng-container>
                            <ng-container >
                              <div  class="text-center">
                                 <label>
                                    <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/mededevice.png" alt="medeil medical device image" loading="lazy" class="fab" />
                                       
                                    </a>
                                    
                                 </label>
                                 <p class="mt-2">Medical device</p>
                              </div>
                            </ng-container>
                           </ng-container>
                      
                    </div>
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                      
                     
                        <ng-container>
                            <ng-container >
                              <div class="text-center">
                                 <label>
                                    <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/pharma.png" alt="medeil pharmacy image icon" loading="lazy" class="fab" />
                                       
                                    </a>
                                 
                                 </label>
                                 <p class="mt-2">Surgical devices</p>
                              </div>
                            </ng-container>
                           </ng-container>
                        
                    </div>
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                       
                       
                        <ng-container>
                            <ng-container >
                              <div  class="text-center">
                                 <label>
                                    <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/pharmacitology.png" alt="medeil pharmacitology image" loading="lazy" class="fab"/>
                                       
                                    </a>
                                   
                                 </label>
                                 <p class="mt-2">Pharmaceutical and Life Sciences</p>
                              </div>
                            </ng-container>
                           </ng-container>
                    </div>  
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 align-self-center">
              <div class="accordions-area">
               
                  <div class="ac-head">
                      <span>Supply chain management</span>
                  </div>
                
                  <div class="ac-body">
                      <div class="content">
                       <p>Supply chain management (SCM) software is used across a wide range of industries to streamline and optimize various aspects of the supply chain process. Here are some industries where SCM software is commonly used to manage start from manufacturing, wholesaling, Retailing logistics & transportation and managing distribution and warehousing in the single cloud application with mobility enabled app solution </p>
                      </div>
                    
                  </div>
           
            </div>
            </div>
          </div>


          <div class="row" *ngIf="manufacturer">
            <div class="col-lg-6 start-product">
                <div class="row product-info" *ngIf="manufacturermainicon">
                    
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon" (click)="manfacturer(1)">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/lifescience.png" alt="lifescience" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Life sciences</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                  
                    <!-- <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon" (click)="manfacturer(2)">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="assets/img/blog/food.png" alt="" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Consumer Food Products companies</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div> -->
                   
                    <!-- <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon"  (click)="manfacturer(3)">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="assets/img/blog/apparel.png" alt="" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Apparel & fashions</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div> -->
                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                       
                     
                        <ng-container>
                            <ng-container >
                              <div  class="text-center">
                                 <label>
                                    <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/mededevice.png" alt="medical device" loading="lazy" class="fab" />
                                       
                                    </a>
                                    
                                 </label>
                                 <p class="mt-2">Medical device</p>
                              </div>
                            </ng-container>
                           </ng-container>
                      
                    </div>
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon"  (click)="manfacturer(4)">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/veterinary.png" alt="veterinary" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Veterinary manufacturer</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                </div>
                <!-- ==========lifescience========== -->
                <div class="row product-info" *ngIf="lifescience">
                    
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/pharma.png" alt="pharma" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Pharmaceutical companies</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                  
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/bio.png" alt="bio" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Biotech companies/p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                   
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/mededevice.png" alt="medeical device" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Medical device companies</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                   
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/ayush.png" alt="ayush" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">AYUSH Companies</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/manfact.png" alt="manfacturer" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Contract Manufacturing Organizations (CMOs)</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/research1.png" alt="research" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Contract Research Organizations (CROs)</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/pharmacitology.png" alt="pharmacitology" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Generic Drug Manufacturers</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/prod.png" alt="prod" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Nutraceutical Companies</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                </div>
              
                <div class="row product-info" *ngIf="veterinary">
                    
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/vat.png" alt="vat image"  loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Veterinary food product manufacturer</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                  
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/vatvat.png" alt="vatvat" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Veterinary Pharmaceutical companies</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                   
                    
                </div>
            </div>
            <div class="col-lg-6 align-self-center">

                <div class="accordions-area">
                  <div class="ac-head">
                      <span>Manufacturers</span>
                  </div>
                
                  <div class="ac-body">
                      <div class="content">
                        <p>Manufacturing business involves considering various factors such as scalability, industry-specific features, integration capabilities, user-friendliness, and cost-effectiveness of implementing solution help to improve your manufacturing capabilities by automation </p>
                      </div>
                    
                  </div>
                       
            </div>
           
           
          </div>
          </div>
          <div class="row" *ngIf="importexport">
            <div class="col-lg-6 start-product">
                <div class="row product-info" *ngIf="importexportmianicon">
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon"(click)="importexportmethod(1)">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/lifescience.png" alt="life science" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Life sciences</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                  
                 
                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                       
                     
                        <ng-container>
                            <ng-container >
                              <div  class="text-center">
                                 <label>
                                    <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/mededevice.png" alt="medecil device" loading="lazy" class="fab" />
                                       
                                    </a>
                                    
                                 </label>
                                 <p class="mt-2">Medical Device Exporter</p>
                              </div>
                            </ng-container>
                           </ng-container>
                      
                    </div>
                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon" (click)="importexportmethod(4)">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/veterinary.png" alt="veterinary" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Veterinary</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div> 
                  
                  
                </div>
                <div class="row product-info" *ngIf="ielifescience">
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/trade12.png" alt="trade image" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Trading Houses and Distributors</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                  
                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/trading.png" alt="trading" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Cold Chain Logistics Providers</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/cosmetics.png" alt="cosmetics" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Cosmeceutical Companies</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                   
                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/imp.png" alt="import image" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Pharmaceutical importer</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div> 
                  
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/impexp2.png" alt="import and export image" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Pharmaceutical Exporter</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>  
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/dtrade.png" alt="trade image" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Pharmaceutical Traders</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>  
                </div>
             
                <div class="row product-info" *ngIf="ieveterrinary">
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/vat.png" alt="vat image" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Animal Health Product Distributors</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                  
                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/vatvat1.png" alt="medeil vat image" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Veterinary Equipment Manufacturers and Suppliers</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/vatvat.png" alt="vat image" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Veterinary Feed Additives and Nutrition Companies</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                   
                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/veterinary.png" alt="veterinary image" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Veterinary Diagnostics and Testing Providers</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div> 
                  
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/bio.png" alt="bio image" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Animal Vaccines and Biological Suppliers</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>  
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/consumer.png" alt="consumer image" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Livestock and Poultry Health Management Companies</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>  
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/impexp2.png" alt="import export image" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Pet Care Product Importers and Exporters</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>  
                </div>
              
            </div>
            <div class="col-lg-6 align-self-center">
          
                <div class="accordions-area">
                  <div class="ac-head">
                      <span>Importer & Export Companies</span>
                  </div>
                
                  <div class="ac-body">
                      <div class="content">
                       <p>Importer and exporter companies are crucial players in global trade, facilitating the movement of goods across borders.The specific activities, focus areas, and business models of each company can vary widely based on factors such as industry sector, geographic location, and market niche with best sotware automation in your Business.</p>
                      </div>
                    
                  </div>
                  
            </div>
           
            
          </div>
          </div>
          <div class="row" *ngIf="candf">
            <div class="col-lg-6 start-product">
                <div class="row product-info" *ngIf="cfmainicon">
              
                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/pharmacymed.png" alt="pharmacy" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Pharmaceutical Wholesalers or Pharmaceutical Distributors</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/mededevice.png" alt="medical device" loading="lazy"class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Medical Device Wholesalers or Medical Device Distributors</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/bio.png" alt="bio image" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Biotechnology Wholesalers or Biotechnology Distributors</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/research1.png" alt="research" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Laboratory Equipment and Supplies Wholesalers</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/otherretail.png" alt="otherretail image" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Specialty Pharmaceuticals Wholesalers or Specialty Product Distributors</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/vat.png" alt="vat image" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Animal Health Product Wholesalers or Veterinary Product Distributors</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/consumer.png" alt="consumer image" loading="lazy" class="fab" />
                                    
                                 </a>
                              
                              </label>
                              <p class="mt-2">Home Healthcare Product Distributors</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                </div>
              
            </div>
            <div class="col-lg-6 align-self-center">
             
                <div class="accordions-area">
                  <div class="ac-head">
                      <span>C&F & Wholesaler</span>
                    
                    
                  </div>
                
                  <div class="ac-body">
                      <div class="content">
                       <p>Wholesalers act as intermediaries between manufacturers and retailers, helping to distribute goods efficiently. Overall, both C&F and wholesalers play crucial roles in facilitating trade by managing the movement of goods and ensuring efficient distribution to end consumers.</p>
                      </div>
                    
                  </div>
                  
            </div>
           
           
          </div>
          </div>
          <div class="row" *ngIf="retailchainstore">
            <div class="col-lg-6 start-product">
                <div class="row product-info" *ngIf="retailchainmainicon">
                 
                  <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/pharmacitology.png" alt="pharmacitology" loading="lazy" class="fab" />
                                 
                              </a>
                           
                           </label>
                           <p class="mt-2">Pharmacy chains</p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>
               
                 <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                     
                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/healthcare.png" alt="healthcare image" loading="lazy"  class="fab" />
                                 
                              </a>
                           
                           </label>
                           <p class="mt-2">Health and Wellness Retail Chains</p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>
                  <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                     
                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/mededevice.png" alt="medical device image" loading="lazy" class="fab" />
                                 
                              </a>
                           
                           </label>
                           <p class="mt-2">Medical Equipment and Supplies Chains</p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>
                  <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                     
                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/optical.png" alt="optical image" loading="lazy" class="fab" />
                                 
                              </a>
                           
                           </label>
                           <p class="mt-2">Optical Retail Chains</p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>
                  <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                     
                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/pharma.png" alt="pharma image" loading="lazy" class="fab" />
                                 
                              </a>
                           
                           </label>
                           <p class="mt-2">Urgent Care and Walk-in Clinic Chains</p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>
                  <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                     
                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/consumer.png" alt="consumer image" loading="lazy" class="fab" />
                                 
                              </a>
                           
                           </label>
                           <p class="mt-2">Specialty Healthcare Chains</p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>
                  <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                     
                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/ayush.png" alt="ayush iamge" loading="lazy" class="fab" />
                                 
                              </a>
                           
                           </label>
                           <p class="mt-2">AYUSH Chain stores</p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>
                  
                </div>
              
            </div>
            <div class="col-lg-6 align-self-center">
   
                <div class="accordions-area">
                  <div class="ac-head">
                      <span>Retail Chain stores / Brand stores</span>
                 
                  </div>
                
                  <div class="ac-body">
                      <div class="content">
                       <p>Retail chain stores are part of a larger network of stores operated by a single company. These stores typically have multiple locations across different regions or even countries. Retail chains can encompass various industries, including apparel, electronics, home goods, and more. Regarding Brand stores, also known as flagship stores or brand retail outlets, are retail locations operated by a specific brand or manufacturer to sell their own products directly to consumers.</p>
                      </div>
                    
                  </div>
 
            </div>
           
            
          </div>
          </div>
          <div class="row" *ngIf="independentretail">
            <div class="col-lg-6 start-product">
                <div class="row product-info" *ngIf="independentmainicon">
                  <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                        
                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/pharmacymed.png" alt="pharmacy medeil image" loading="lazy" class="fab" />
                                 
                              </a>
                           
                           </label>
                           <p class="mt-2">Pharmacies</p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>
                
                 <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                     
                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/ayush.png" alt="ayush image" loading="lazy" class="fab" />
                                 
                              </a>
                           
                           </label>
                           <p class="mt-2">Ayurvedic stores</p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>
                  <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                     
                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/consumer.png" alt="consumer image" loading="lazy" class="fab" />
                                 
                              </a>
                           
                           </label>
                           <p class="mt-2">Wellness store</p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>
                
                 <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                     
                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/healthcare.png" alt="healthcare image" loading="lazy" class="fab" />
                                 
                              </a>
                           
                           </label>
                           <p class="mt-2">Healthcare store</p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>
                 <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                     
                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/mededevice.png" alt="medical device image" loading="lazy" class="fab" />
                                 
                              </a>
                           
                           </label>
                           <p class="mt-2">Surgical store</p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>
                 <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                     
                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/optical.png" alt="optical image" loading="lazy" class="fab" />
                                 
                              </a>
                           
                           </label>
                           <p class="mt-2">Optical shop </p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>
                 <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">
                     
                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/prod.png" alt="product image" loading="lazy" class="fab" />
                                 
                              </a>
                           
                           </label>
                           <p class="mt-2">Agrochemical</p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>
                  
                </div>
               
            </div>
            <div class="col-lg-6 align-self-center">
         
                <div class="accordions-area">
                  <div class="ac-head">
                      <span>Independent Retail stores</span>
                    
                     
                  </div>
                
                  <div class="ac-body">
                      <div class="content">
                       <p>Independent retail stores are more than just places to shop—they are integral parts of the social, economic, and cultural fabric of their communities. By supporting independent retailers, consumers contribute to the vitality and diversity of their local economies while enjoying the benefits of personalized service and unique shopping experiences</p>
                      </div>
                    
                  </div>
             
            </div>
           
          </div>
          </div>
        </div>
    </section> 

</div>

<div class="application-area-two mt-3">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7">
                <div class="application-content">
                
                    <div class="section-title">
                        <!-- <span>Download app</span> -->
                        <h2><strong>Medeil</strong> Your Pharmacy Companion</h2>
                        <p>Streamline Your Pharmacy Operations Effortlessly</p>
                    </div>

                    <div class="application-btn">
                        <a class="application-play-btn"  href="https://play.google.com/store/apps/details?id=com.medeilpos.app" target="_blank" rel="noopener">
                            <img src="assets/img/google-play.svg" alt="medeil play store image" loading="lazy">
                            <div class="btn-content">
                                <span>ANDROID APP ON</span>
                                <h3>Google Play</h3>
                            </div>
                        </a>
                        <!-- <a href="#" class="application-play-btn">
                            <img src="assets/img/company.svg" alt="svg">
                            <div class="btn-content">
                                <span>DOWNLOAD ON THE</span>
                                <h3>App Store</h3>
                            </div>
                        </a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-5 text-center" >
                <div class="application-img-two">
                    <img src="assets/img/products/mobile-black.png" alt="medeil mobile image" height="250px" width="130px" loading="lazy">
                </div>
            </div>
        </div>
    </div>
</div>
<section class="category-box-area mt-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="category-box-card">
                    <a routerLink="/"><img src="https://medeilpos.blob.core.windows.net/websiteimage/award_financeonline.png" alt="award_financeonline" loading="lazy"></a>
                    
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="category-box-card">
                    <a routerLink="/"><img src="https://medeilpos.blob.core.windows.net/websiteimage/capterra.png" alt="capterra" loading="lazy"></a>
                   
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="category-box-card">
                    <a routerLink="/"><img src="https://medeilpos.blob.core.windows.net/websiteimage/financeonline.png" alt="financeonline" loading="lazy"></a>
                  
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="category-box-card ">
                    <a routerLink="/"><img src="https://medeilpos.blob.core.windows.net/websiteimage/software-suggest.png" alt="software-suggest" loading="lazy"></a>
                  
                </div>
            </div>

          
        </div>
    </div>
</section>

<!-- ---------------------------------------- -->


<app-footer-style-two></app-footer-style-two>
 <!-- <div class="privacy-data" *ngIf="isVisible">
   <div class="col-data">
     <p>We use cookies to improve your experience on our site. By using our site you consent to cookies. <a href="https://www.vanuston.com/Terms-and-Conditions/">Learn more</a> 
       <button class="cookie-btn" (click)="Acceptall()">Accept All</button>
       <button class="cookie-btn" (click)="declineAll()">Decline All</button>
     </p>
   </div>
 </div> -->
 