<app-navbar-style-two></app-navbar-style-two>

<app-medeilnavbar></app-medeilnavbar>


<section class="pricing-area pt-4 pb-40">
    <div class="container-fluid">
        <div class="section-title text-center">
            <!-- <span>Pricing</span> -->
            <h2>Our Pricing Tables</h2>
        </div>

        <div class="row pt-45">
            <!-- <div class="col-lg-1"></div> -->
            <div class="col-lg-3 col-md-6">
                <div class="price-card">
                    <div class="content">
                        
                        <h3>Free Edition</h3>
                    
                    </div>
                    <ul>
                        <li>Startup pharmacy </li>
                        <li>Customer relationship management</li>
                        <li>Distributor management </li>
                        <li>Basic Inventory management </li>
                        <li>billing & invoicing</li>
                        <li>Basic Accounting</li>
                        <li>No Pharmacy Labeling</li>
                        <li>10 Reports</li>
                        <li>Limited Records </li>
                    </ul>
                    <a class="default-btn" (click)="trynowbtn('Medeil pricing Free Edition')">Free Edition</a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="price-card">
                    <div class="content">
                      
                        <h3>Express Edition</h3>
                      
                    </div>
                    <ul>
                        <li>Suitable for Small Pharmacies</li>
                        <li>Customer relationship management</li>
                        <li>Distributor management </li>
                        <li>Basic Inventory management </li>
                        <li>billing & invoicing</li>
                        <li>Basic Accounting</li>
                        <li>No Pharmacy Labeling</li>
                        <li>35 Reports</li>
                        <li>Unlimited </li>
                    </ul>
                    <a class=" default-btn" (click)="trynowbtn('Medeil pricing Express Edition')">Demo Request</a>
                </div>
            </div>
            <!-- <div class="col-lg-1"></div> -->
            <!-- <div class="col-lg-1"></div> -->
            <div class="col-lg-3 col-md-6 offset-md-3 offset-lg-0">
                <div class="price-card">
                    <div class="content">
                      
                        <h3>Standard Edition</h3>
                     
                    </div>
                    <ul style="font-size: 15px;">
                        <li>Suitable for Medium or standard size pharmacies</li>
                        <li>Customer relationship management</li>
                        <li>Distributor management </li>
                        <li>Standard Inventory management </li>
                        <li>billing & invoicing</li>
                        <li>Standard Accounting </li>
                        <li>No Pharmacy Labeling</li>
                        <li>75 Reports</li>
                        <li>Unlimited</li>
                    </ul>
                    <a  class="default-btn" (click)="trynowbtn('Medeil pricing Standard Edition')">Download Trial</a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 offset-md-3 offset-lg-0">
                <div class="price-card">
                    <div class="content">
                      
                        <h3>Professional Edition</h3>
                     
                    </div>
                    <ul>
                        <li>Professional Pharmacies</li>
                        <li>Customer relationship management</li>
                        <li>Distributor management </li>
                        <li>Advanced Inventory management </li>
                        <li>billing & invoicing</li>
                        <li>Standard Accounting </li>
                        <li>Pharmacy Labeling</li>
                        <li>135 Reports</li>
                        <li>Unlimited</li>
                    </ul>
                    <a  class="default-btn" (click)="trynowbtn('Medeil pricing Professional Edition')">Demo Request</a>
                </div>
            </div>
            <!-- <div class="col-lg-1"></div> -->
        </div>
    </div>
</section>
<ngx-smart-modal #myModal identifier="myModal" [closable]="false">

    <div class="custom-modal">
        <button class="close-btn" (click)="closepopup()">X</button>
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Enquiry Form</h5>
        </div>
        <div class="modal-body">
         <form [formGroup]="enquiryForm">
           <div class="form-group">
             <label for="recipient-name" class="col-form-label"><strong>Name</strong><span class="star">*</span></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="leadname" required>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Organisation</strong></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="organizationname" required>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Email</strong><span class="star">*</span></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="primaryemail" required>
             <div *ngIf="enquiryForm.controls.primaryemail.invalid && (enquiryForm.controls.primaryemail.dirty || enquiryForm.controls.primaryemail.touched)">
              <div *ngIf="enquiryForm.controls.primaryemail.errors?.required" style="color: red;">Email is required.</div>
              <div *ngIf="enquiryForm.controls.primaryemail.errors?.pattern" style="color: red;">Invalid email format.</div>
            </div>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Country</strong></label>
             <!-- <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="countryname1"> -->
             <select class="form-control"  formControlName="countryname1" style="font-size: 13px;" (change)="countrychange()">
              <!-- (change)="countrychange()" -->
              <option select disabled value="0" >---Select Country---</option>
              <option  value="{{ country[1] }}" *ngFor="let country of totalcountry">{{country[1]}}</option>
  
            </select>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Mobile</strong><span class="star">*</span></label>
             <div class="row">
               <div class="col-lg-3">
                 <input type="text" name="rec" readonly class="form-control mobile-field" [(ngModel)]="dialcode" id="recipient-name" formControlName="phonedialcode">
               </div>
               <div class="col-lg-9">
                 <input type="text" name="rec" class="form-control mobile-field" maxlength="15" id="recipient-name" formControlName="primaryphone"
                 autocomplete="off" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')">
               </div>
             </div>
            
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Description</strong></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="address1">
           </div>
           <!-- <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Enter captcha</strong></label>
             <input type="text" name="rec" class="form-control" id="recipient-name">
           </div> -->
           <label for="message-text" class="col-form-label captchalabel"><strong>Enter captcha</strong></label>
           <div class="input-group">
              <input type="text" class="form-control ecaptcha" required formControlName="enteredcaptcha" required="required"
               (change)="checkCaptcha()"/>
              <input type="text" class="form-control gcaptcha" formControlName="generatedcaptcha" readonly
              [(ngModel)]="captcha"/>
              <i class="fa fa-arrows-rotate refresh" (click)="generatecaptcha()"></i>
            </div>
            <div *ngIf="ecaptcha">
              <p style="color: red">{{ errorcaptcha }}</p>
            </div>
           <div class="form-group">
            
                 <label class="container1 mt-3">I Accept Terms & Conditions & Privacy Policy
                     <input type="checkbox" checked="checked" formControlName="checkbox">
                     <span class="checkmark"></span>
                 </label>
           </div>
           <div  align="center" *ngIf="showElement">
            <img height="50px" width="50px" src="https://medeilpos.blob.core.windows.net/posimage/loading.gif" alt="gif" loading="lazy">
          </div>
           <div class="btn-group mt-3" >
             <button type="button" class="enquiry-btn" (click)="savesignup()">Submit</button>

           </div>
          
           
         </form>
       </div>
    </div>
   
</ngx-smart-modal>
<div class="container">
    <div class="section-title text-center">
        <!-- <span>Pricing</span> -->
        <h2>Can’t decide which plan is right for you?</h2>

    </div>
    <div class="text-center">
        <button class="comparison" (click)="toggleComparison()">Click Here for Comaparision table</button>
    </div>
    <div class="enquery" *ngIf="opencomparison">
        <form [formGroup]="comparisonform" class="form-container">
           
                <input type="text" formControlName="leadname" class="form-control" placeholder="Enter Name">
                <input type="number" formControlName="moblieno" class="form-control" placeholder="Enter Mobile">
                <input type="text" formControlName="emailid" class="form-control" placeholder="Enter Email">
                <div *ngIf="comparisonform.controls.emailid.invalid && (comparisonform.controls.emailid.dirty || comparisonform.controls.emailid.touched)">
                    <!-- <div *ngIf="comparisonform.controls.emailid.errors?.required" style="color: red;">Email is required.</div> -->
                    <div *ngIf="comparisonform.controls.emailid.errors?.pattern" style="color: red;">Invalid email format.</div>
                  </div>
                  <div  align="center" *ngIf="showgif">
                    <img height="100px" width="100px" src="https://medeilpos.blob.core.windows.net/posimage/loading.gif" alt="gif" loading="lazy">
                  </div>
                <div class="btn-contain">
                    <button class="button-sub" role="button" (click)="savectable()">Submit</button>
                </div>
              


        </form>
    </div>
    
</div>
<div class="container mt-4">
    <div class="section-title text-center">
        <!-- <span>Pricing</span> -->
        <h2>Frequently Asked Questions</h2>
    </div>
    <div class="row pt-45">
        <div class="col-lg-6">
            <div class="faq-item">
                <h4>Q: What is MEDEIL?</h4>
                <p><strong>A:</strong> MEDEIL is a comprehensive pharmacy management software developed by Vanuston Intelligence. It is designed to streamline pharmacy operations, manage inventory, process prescriptions, and enhance patient care.</p>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="faq-item">
                <h4>Q: Is MEDEIL easy to use?</h4>
                <p><strong>A:</strong> MEDEIL is designed with user-friendly interfaces and intuitive navigation to make it easy for pharmacy staff to learn and use the software efficiently.</p>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="faq-item">
                <h4>Q: Does MEDEIL offer technical support and training?</h4>
                <p><strong>A:</strong> Yes, MEDEIL typically offers technical support and training to help pharmacies implement the software successfully and address any technical issues or questions that may arise.</p>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="faq-item">
                <h4>Q: How can I get more information about MEDEIL?</h4>
                <p><strong>A:</strong> For more information about MEDEIL, including pricing, features, and implementation, you can visit the official website of Vanuston Intelligence (vanuston.com) and its product websites medeil.com & medeilcloud .com or contact their sales or support team directly.</p>
            </div>
        </div>
    </div>

</div>
<app-footer-style-two></app-footer-style-two>