<app-navbar-style-two></app-navbar-style-two>

<!-- <app-posnavbar></app-posnavbar> -->
<div class="banner-area-two client-slider owl-carousel owl-theme">

   
    <div class="client-item">
        <img src="https://medeilpos.blob.core.windows.net/websiteimage/veterinarysoftwaremedeilcloudpos.png" alt="veterinary software medeilcloud pos">
        <div class="overlay-buttons row">
            <div class="col-lg-6">
                <button type="button" class="default-btn1">14 Days Free Trial</button>
            </div>
            <div class="col-lg-6">
                <button type="button" class="default-btn1" (click)="appointment('Veterinary Vertical')">Demo Request</button>
            </div>
              
              
        </div>
    </div>

</div>
<ngx-smart-modal #enquirypos identifier="enquirypos" [closable]="false">


  <div class="custom-modal">
     <button class="close-btn" (click)="closeModal()">X</button>
     <div class="modal-header">
         <h5 class="modal-title" id="exampleModalLabel">Enquiry Form</h5>
     </div>
       <div class="modal-body">
         <form [formGroup]="enquiryForm">
           <div class="form-group">
             <label for="recipient-name" class="col-form-label"><strong>Name</strong><span class="star">*</span></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="leadname" required>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Organisation</strong></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="organizationname" required>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Email</strong><span class="star">*</span></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="primaryemail" required>
             <div *ngIf="enquiryForm.controls.primaryemail.invalid && (enquiryForm.controls.primaryemail.dirty || enquiryForm.controls.primaryemail.touched)">
              <div *ngIf="enquiryForm.controls.primaryemail.errors?.required" style="color: red;">Email is required.</div>
              <div *ngIf="enquiryForm.controls.primaryemail.errors?.pattern" style="color: red;">Invalid email format.</div>
            </div>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Country</strong></label>
             <!-- <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="countryname1"> -->
             <select class="form-control"  formControlName="countryname1" style="font-size: 12px;" (change)="countrychange()">
              <!-- (change)="countrychange()" -->
              <option select disabled value="0" >---Select Country---</option>
              <option  value="{{ country[1] }}" *ngFor="let country of totalcountry">{{country[1]}}</option>
  
            </select>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Mobile</strong><span class="star">*</span></label>
             <div class="row">
               <div class="col-lg-3">
                 <input type="text" name="rec" readonly class="form-control mobile-field" [(ngModel)]="dialcode" id="recipient-name" formControlName="phonedialcode">
               </div>
               <div class="col-lg-9">
                 <input type="text" name="rec" class="form-control mobile-field" maxlength="15" id="recipient-name" formControlName="primaryphone"
                 autocomplete="off" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')">
               </div>
             </div>
            
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Description</strong></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="address1">
           </div>
           <!-- <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Enter captcha</strong></label>
             <input type="text" name="rec" class="form-control" id="recipient-name">
           </div> -->
           <label for="message-text" class="col-form-label captchalabel"><strong>Enter captcha</strong></label>
           <div class="input-group">
              <input type="text" class="form-control ecaptcha" required formControlName="enteredcaptcha" required="required"
               (change)="checkCaptcha()"/>
              <input type="text" class="form-control gcaptcha" formControlName="generatedcaptcha" readonly
              [(ngModel)]="captcha"/>
              <i class="fa fa-arrows-rotate refresh" (click)="generatecaptcha()"></i>
            </div>
            <div *ngIf="ecaptcha">
              <p style="color: red">{{ errorcaptcha }}</p>
            </div>
           <div class="form-group">
            
                 <label class="container1 mt-3">I Accept Terms & Conditions & Privacy Policy
                     <input type="checkbox" checked="checked" formControlName="checkbox">
                     <span class="checkmark"></span>
                 </label>
           </div>
           <div class="btn-group mt-3" *ngIf="!showElement">
             <button type="button" class="enquiry-btn" (click)="submitenquery()" >Submit</button>

           </div>
          
           
         </form>
       </div>
 </div>
</ngx-smart-modal>
<ngx-smart-modal #appointmentform identifier="appointmentform" [closable]="false">


  <div class="custom-modal"  >
     <button class="close-btn" (click)="closeModal()">X</button>
      <div class="modal-header-appointment">
          <h4 class="modal-title" id="exampleModalLabel">Demo Request</h4>
      </div>
        <div class="modal-body">
       
           <div class="row">
              <div class="col-lg-12">
                 <mat-form-field >
                   
                    <input matInput [matDatepicker]="picker" placeholder="Choose a date" (dateChange)="slectdate($event)" >
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                 </mat-form-field>
                
              </div>
              <div  align="center" *ngIf="showElement">
                 <img height="200px" width="200px" src="https://medeilpos.blob.core.windows.net/posimage/loading.gif" alt="gif" loading="lazy">
               </div>
              <div class="col-lg-12" *ngIf="appointment_time">
                 
                  <div class="button-container">
                    <button class="flex-button" *ngFor="let time of timeSlots" (click)="selectTime(time)" [ngClass]="{ 'clicked': selectedTime === time }">{{ time }}</button>
                </div>
                <div  align="center" *ngIf="showElementsave">
                  <img height="100px" width="100px" src="https://medeilpos.blob.core.windows.net/posimage/loading.gif" alt="gif" loading="lazy">
                </div>
                  <div class="shedule-submit">
                    <button class="shedulebtn" (click)="savelead()">Submit</button>
                  </div>
              </div>
           </div>

        </div>
  </div>
</ngx-smart-modal>
<div class="newslider" >

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/anu-pharmacy.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/apollo-hospitals.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/bellamed-healthcare.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/bk-heart-hospital.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/booncholai-super-market.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/emedsure.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/express-pharmacy.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/falcon-healthcare.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/global-bridge-pharmaceutical.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/hawi-family-hostpital.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/henry-jackson-foundation.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/imcri.webp" alt="image" loading="lazy">
  </div>

</div>
<!-- <div class="section-title container gradient-text mb-2" >
  
    <h2>Features</h2>
</div> -->
<!-- ---------------------------- -->


<div id="services" class="services section">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div class="section-heading  wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
            <div class="gradient-text"><h1>Amazing  Services &amp; Features </h1>for you</div>
            <!-- <img src="assets/images/heading-line-dec.png" alt=""> -->
         <p>Developing a cutting-edge software system for a veterinary clinic entails adding sophisticated features that optimize workflow, boost client satisfaction, and boost productivity all around.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <div class="service-item first-service">
            <div class="icon"></div>
            <h4>AI Diagnosis Assistance</h4>
            <p>Veterinarians can receive assistance in accurately diagnosing and treating patients by utilizing AI-powered diagnostic tools that can evaluate symptoms, medical histories, and test findings.</p>
            <div class="text-button">
              <a href="#">Read More <i class="fa fa-arrow-right"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="service-item second-service">
            <div class="icon"></div>
            <h4>Robotic Surgical</h4>
            <p>Introduce robotic surgical technologies to let veterinarians perform minimally invasive treatments with control and precision. This will improve surgical results and shorten patient recovery times.</p>
            <div class="text-button">
              <a href="#">Read More <i class="fa fa-arrow-right"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="service-item third-service">
            <div class="icon"></div>
            <h4>Preventative care</h4>
           <p>
            Personalized preventative care plans and proactive interventions are made possible by using predictive analytics algorithms to examine patient data and find trends that may point to possible health problems.
           </p>
            <div class="text-button">
              <a href="#">Read More <i class="fa fa-arrow-right"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="service-item fourth-service">
            <div class="icon"></div>
            <h4>Biometric Authentication</h4>
            <p style="line-height: 1.3;">
                Use biometric authentication techniques, like fingerprint or facial recognition, to provide safe access to sensitive patient data and records while improving user convenience and privacy protection.
            </p>
            <div class="text-button">
              <a href="#">Read More <i class="fa fa-arrow-right"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- ---------------------------- -->

<!-- about  -->
<div id="about" class="about-us section">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 align-self-center">
        <div class="section-heading">
          <h4>About what we do</h4>
          <!-- <img src="assets/images/heading-line-dec.png" alt=""> -->
          <!-- <img src="assets/images/heading-line-dec.png" alt=""> -->
         <p>
            We concentrate on creating cutting-edge software solutions that are customized to meet the particular requirements of hospitals, veterinary clinics, and pet care facilities. Our mission is to provide vets and other pet care providers with state-of-the-art tools that optimize workflows, improve patient outcomes, and boost overall productivity.
         </p>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="box-item" routerLink="/medeil">
                  <h5><a >Standalone Version</a></h5>
                  <!-- <p class="ptag">Lorem Ipsum Text</p> -->
                </div>
              </div>
              <div class="col-lg-6">
                <div class="box-item" routerLink="/medeilcloudpos">
                  <h5><a >SaaS Version</a></h5>
                  <!-- <p class="ptag">Lorem Ipsum Text</p> -->
                </div>
              </div>
              <div class="col-lg-6">
                <div class="box-item" routerLink="/medeilcloudhq">
                  <h5><a >Chain Store Version</a></h5>
                  <!-- <p class="ptag">Lorem Ipsum Text</p> -->
                </div>
              </div>
              <div class="col-lg-6">
                <div class="box-item" routerLink="/allotmed">
                  <h5><a >Distribution Version</a></h5>
                  <!-- <p class="ptag">Lorem Ipsum Text</p> -->
                </div>
              </div>
          <div class="col-lg-12">
            <p>
                At Vanuston, we are more than just a provider of veterinary goods and services—we are a dependable resource and partner that supports veterinary practices and companies in providing the best possible care to their patients and clients.
            </p>
            <div>
             <!-- <button class="gradient-button">Start Free Trial</button> -->
            </div>
            <!-- <span>*No Credit Card Required</span> -->
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="right-image">
          <img src="https://medeilpos.blob.core.windows.net/websiteimage/medicalposbilling- medeilcloudpos.gif" alt="medical pos billing- medeilcloud pos" loading="lazy">
        </div>
      </div>
    </div>
  </div>
</div>
<!-- ---- -->
<div class="container mt-4">
    <div class="section-title gradient-text mt-2 mb-2" >
       
        <h1>Frequently Asked Questions</h1>
    </div>
    <div class="row pt-4">
        <div class="col-lg-6 col-md-12">
            <div class="faq-item">
                <h4>Q: Which kinds of drugs and supplies do you provide for animals?</h4>
                <p><strong>A:</strong> Consumers frequently inquire about the variety of drugs and supplies that are offered for different kinds of animals, such as over-the-counter vitamins, prescription drugs, vaccinations, and specialized goods like veterinary diets and compounded drugs.</p>
            </div>
        </div>
    
        <div class="col-lg-6 col-md-12">
            <div class="faq-item">
                <h4>Q: How can your Veterinary software improve clinic operations efficiency?</h4>
                <p><strong>A:</strong> This inquiry pertains to the MEDEILCloud POS & HQ software's advantages in terms of productivity and efficiency. Features like inventory tracking, appointment scheduling, electronic health record management, and automated billing procedures that save administrative work and boost workflow efficiency could be among the solutions.</p>
            </div>
        </div>
    
        <div class="col-lg-6 col-md-12">
            <div class="faq-item">
                <h4>Q: Can your software be tailored to meet the unique requirements of our Pet pharmacy & clinic?</h4>
                <p><strong>A:</strong> This inquiry looks for details regarding the software's versatility and customization options. Information on user interfaces, workflow configurations, and templates that can be customized to a clinic's specific needs and preferences may be included in the answers.</p>
            </div>
        </div>
    
        <div class="col-lg-6 col-md-12">
            <div class="faq-item">
                <h4>Q: What type of assistance and training are available for setting up and utilising the software? </h4>
                <p><strong>A:</strong> This inquiry relates to the software vendor's support and training offerings. In addition to providing clinics with access to instructional materials, webinars, and individualized support from a dedicated support team, the answers should outline the training resources, onboarding aid, and continuing support choices available to assist clinics in successfully implementing and using the software.</p>
            </div>
        </div>
    </div>
    
    
</div>
<div class="row mb-3 mt-3" style="width: 100%;">
  <div class="col-lg-2">
      <div class="rating-section">
          <h6>Crowd Review</h6>
          <div class="rating-stars">
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9734;</div>
          </div>
          <p>4.3/5.0</p>
        </div>
  </div>
  <div class="col-lg-2">
      <div class="rating-section">
          <h6>Google</h6>
          <div class="rating-stars">
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9734;</div>
          </div>
          <p>4.8/5.0</p>
        </div>
  </div>
  <div class="col-lg-2">
      <div class="rating-section">
          <h6>Capterra</h6>
          <div class="rating-stars">
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9734;</div>
          </div>
          <p>4.4/5.0</p>
        </div>
  </div>
  <div class="col-lg-2">
      <div class="rating-section">
          <h6>Software Suggest</h6>
          <div class="rating-stars">
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9734;</div>
          </div>
          <p>4.7/5.0</p>
        </div>
  </div>
  <div class="col-lg-2">
      <div class="rating-section">
          <h6>G2 Crowd</h6>
          <div class="rating-stars">
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9734;</div>
          </div>
          <p>4.1/5.0</p>
        </div>
  </div>
  <div class="col-lg-2">
      <div class="rating-section">
          <h6>Trust Radius</h6>
          <div class="rating-stars">
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9734;</div>
          </div>
          <p>4.6/5.0</p>
        </div>
  </div>
</div>
<app-footer-style-two></app-footer-style-two>

