import { Component } from '@angular/core';

@Component({
  selector: 'app-surgicaldevice',
  templateUrl: './surgicaldevice.component.html',
  styleUrls: ['./surgicaldevice.component.scss']
})
export class SurgicaldeviceComponent {

}
